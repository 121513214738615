import React, { useState } from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import { ContainerFullWidth, ContainerBodyWidth } from '../containers'
import { MdNetworkCell } from "react-icons/md";
import Img from "gatsby-image"
import ToolkitImg from "../assets/toolkit_website_banner.png";



const AdPanel = styled(ContainerBodyWidth)`
  padding: 0px 0px;
  /* padding: 0px; */
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.theme.border.secondary};
  /* background-color: ${(props) => props.theme.theme.bg.primary}; */
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border-radius: 8px;
  /* display: flex; */
  display: grid;
  grid-template-columns: 1fr 30px;
  grid-gap: 4px;
  align-items: center;
  /* border-bottom: 2px solid ${props => props.theme.theme.colors.blue}; */
  margin: 24px auto auto auto;

  &.hidden {
    display: none;
  }

  &:hover {}

  h3, p {
    margin: 4px 0;
    font-family: 'Inter', sans-serif;
  }

  p {
    font-size: 1rem;
  }

  svg {
    color: ${props => props.theme.theme.colors.mintMobileGreen};

    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: 700px) {
    width: 95vw;
    bottom: 2.5vw;
    right: 2.5vw;
    font-size: 0.8rem;
    grid-template-columns: 1fr 30px;
  }

  &:hover{
    cursor: pointer;
    border: 1px solid ${props => props.theme.theme.colors.mintMobileGreen};
    /* background-color: ${(props) => props.theme.theme.colors.transparentGreenHover}; */
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
  }

  transition: box-shadow 0.1s ease-in-out;
  transition: border-color 0.1s ease-in-out;
`

const AdContent = styled.div`
  width: 100%;
  padding: 12px;

  h3, p {
    margin: 12px 0;
    color: ${props => props.theme.theme.colors.text.primary};
  }

  h3 {
    font-size: 1.1rem;
    font-weight: 400;
  }

  p {
    font-size: 1.0rem;
    color: ${props => props.theme.theme.colors.text.secondary};
  }
`

const Highlight = styled.span`
    background-color: ${(props) => props.theme.theme.colors.mintMobileGreenTransparent};
    padding: 4px;
    border-radius: 4px;
`

const Wordmark = styled.span`
  color: ${props => props.theme.theme.colors.green};
  font-weight: 600;
  border-bottom: 2px solid ${props => props.theme.theme.colors.green};

  &:hover {
    }
`

const AdHeaderGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 200px;
  grid-gap: 12px;
  align-items: center;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`

const Image = styled.img`
  width: 100%;
`

export const GumroadAd = () => {
  return(
      <a href="https://walletburst.gumroad.com/l/wealth-planning-toolkit" target="_blank">
        <AdPanel >
          <AdContent>
            <AdHeaderGrid>
              <div>

              <h3>
                Take your <strong>wealth planning to the next level</strong> with my <Wordmark>Wealth Planning Toolkit</Wordmark> for Google Sheets - just $20. 
              </h3>

              <p>
                <Highlight>Plan for recurring monthly income over different time periods as well as windfalls and one-time purchases in the future.</Highlight>
              </p>
              <p> 
                Includes <strong>10 year Net Worth Tracker with Dashboard</strong>, <strong>Asset Rebalancing Calculator</strong>, and <strong>advanced FIRE and Coast FIRE Calculators</strong>.
              </p>
              </div>

              <Image src={ToolkitImg} style={{maxWidth: '600px'}}/>
              
            </AdHeaderGrid>
          
        </AdContent>
      </AdPanel>
    </a>
  )
}
