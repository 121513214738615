import React, { useState } from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import { ContainerFullWidth, ContainerBodyWidth } from '../containers'
import { MdNetworkCell } from "react-icons/md";
import Img from "gatsby-image"


const AdPanel = styled(ContainerBodyWidth)`
  padding: 0px 0px;
  /* padding: 0px; */
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.theme.border.secondary};
  /* background-color: ${(props) => props.theme.theme.bg.primary}; */
  background-color: ${(props) => props.theme.theme.colors.mintMobileGreenLight};
  border-radius: 8px;
  /* display: flex; */
  display: grid;
  grid-template-columns: 1fr 30px;
  grid-gap: 4px;
  align-items: center;
  /* border-bottom: 2px solid ${props => props.theme.theme.colors.blue}; */

  p {
    font-size: 1rem;
  }

  &.hidden {
    display: none;
  }

  &:hover {}

  h3, p {
    margin: 4px 0;
    font-family: 'Inter', sans-serif;
  }

  svg {
    color: ${props => props.theme.theme.colors.mintMobileGreen};

    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: 700px) {
    /* width: 95vw; */
    bottom: 2.5vw;
    right: 2.5vw;
    font-size: 0.8rem;
    grid-template-columns: 1fr 30px;
  }

  &:hover{
    cursor: pointer;
    border: 1px solid ${props => props.theme.theme.colors.mintMobileGreen};
    /* background-color: ${(props) => props.theme.theme.colors.transparentGreenHover}; */
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
  }

  transition: box-shadow 0.1s ease-in-out;
  transition: border-color 0.1s ease-in-out;
`

const AdContent = styled.div`
  width: 100%;
  padding: 12px;

  h3, p {
    margin: 0;
    color: ${props => props.theme.theme.colors.text.primary};
  }

  h3 {
    font-size: 1rem;
    font-weight: 400;
  }

  p {
    font-size: 0.9rem;
    color: ${props => props.theme.theme.colors.text.tertiary};
  }
`

const Highlight = styled.span`
    background-color: ${(props) => props.theme.theme.colors.mintMobileGreenTransparent};
    padding: 4px;
    border-radius: 4px;
`

const Wordmark = styled.span`
  border-bottom: 1px solid ${props => props.theme.theme.colors.mintMobileGreen};

  font-weight: 600;
  border-bottom: 2px solid ${props => props.theme.theme.colors.mintMobileGreen};

  &:hover {
    }
`

const AdHeaderGrid = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-gap: 12px;
  align-items: center;
`

export const MintMobileAd = () => {
  return(
      <a href="http://fbuy.me/rYaXV" target="_blank">
        <AdPanel >
          <AdContent>
            <AdHeaderGrid>
              <MdNetworkCell size="30px" />
              <h3>
              <Wordmark>Mint Mobile</Wordmark> is America's  <Highlight>best value</Highlight> wireless plan.
              </h3>
            </AdHeaderGrid>

            <p>
              Get premium wireless service on the nation's largest 5G network, with plans ranging 
              from <strong>$15/mo for 4GB data</strong> to <strong>$30/mo for unlimited data</strong>.
          </p>
        </AdContent>
      </AdPanel>
    </a>
  )
}
